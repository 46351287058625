



























import Vue from 'vue';

export default Vue.extend({
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    }
  }
});
