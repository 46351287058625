import Vue from 'vue';
import { Machine } from '@/model/machine';
import { mapStores } from 'pinia';
import { useMachinesStore } from '@/store/machines-store';
import { useMachineModelsStore } from '@/store/machine-models-store';

export default Vue.extend({
  computed: {
    ...mapStores(useMachinesStore, useMachineModelsStore),
    currentMachine(): Machine {
      return this.machinesStore.currentEntity;
    },
    validationErrors(): any {
      return this.machinesStore.validationErrors;
    },
    sendingData(): boolean {
      return this.machinesStore.saving;
    }
  }
});
