




















































import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import Search from '@/components/shared/Search.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import BaseResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useLocationsStore } from '@/store/locations-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { Workplace } from '@/model/workplace';
import { Location } from '@/model/location';
import {
  useUiStore,
  WorkplacePanel,
  WORKPLACES_PANELS_LAYOUT
} from '@/store/ui-store';
import ConfigListElement from '@/components/shared/ConfigListElement.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseResourceConfigMixin>
>).extend({
  components: {
    LoadingIndicator,
    Search,
    EmptyDataset,
    ConfigListElement
  },
  mixins: [BaseResourceConfigMixin],
  data() {
    return {
      searchTerms: ''
    };
  },
  computed: {
    ...mapStores(useUiStore, useLocationsStore, useWorkplacesStore),
    isFetchingLocations(): boolean {
      return this.locationsStore.fetching;
    },
    isFetchingWorkplaces(): boolean {
      return this.workplacesStore.fetching;
    },
    locations(): Location[] {
      return this.locationsStore.entities;
    },
    currentWorkplace(): Workplace {
      return this.workplacesStore.currentEntity;
    },
    workplaces(): Workplace[] {
      return this.workplacesStore.entities;
    }
  },
  methods: {
    getWorkplacesForLocationId(locationId: number): Workplace[] {
      return this.workplacesStore.getForLocationId(locationId);
    },
    editWorkplace(workplace) {
      this.uiStore.openPanel(
        WORKPLACES_PANELS_LAYOUT,
        WorkplacePanel.EDIT_WORKPLACE
      );
      this.workplacesStore.setCurrentEntity(workplace);
    },
    getWorkplacesFiltered(workplacesFromLocation): Workplace[] {
      if (this.searchTerms) {
        return workplacesFromLocation.filter(workplace => {
          const splitVal = this.searchTerms.split(' ');
          const joinedSplitVal = splitVal.join('.*');
          const regex = new RegExp(joinedSplitVal, 'gi');
          return regex.test(`${workplace.name}`);
        });
      }

      return workplacesFromLocation;
    }
  }
});
