


















import MachineForm from '@/components/machines/MachineForm.vue';
import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import BaseMachineMixin from '@/components/machines/BaseMachineMixin';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { ServicePanel, useUiStore } from '@/store/ui-store';
import { useMachinesStore } from '@/store/machines-store';
import { Machine } from '@/model/machine';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseMachineMixin>
>).extend({
  components: {
    MachineForm,
    BackButtonTitle
  },
  mixins: [BaseMachineMixin],
  props: {
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapStores(useUsersStore, useUiStore, useMachinesStore),
    vendorId(): number | null {
      return this.usersStore.loggedInVendorId;
    }
  },
  methods: {
    closePanel() {
      this.uiStore.closePanel(ServicePanel.ADD_MACHINE);
    },
    async addMachine({ machine }: { machine: Partial<Machine> }) {
      const machineAdded = await this.machinesStore.create(machine);
      if (machineAdded) {
        this.machineModelsStore.addMachineToHisModelInStore(machineAdded);
      }

      if (
        !this.sendingData &&
        Object.keys(this.validationErrors).length === 0
      ) {
        this.closePanel();
      }
    }
  }
});
