

















import ServiceForm from '@/components/services/ServiceForm.vue';
import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import BaseServiceMixin from '@/components/services/BaseServiceMixin';
import ResourceMixin from '@/mixins/ResourceMixin.ts';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { ServicePanel, useUiStore } from '@/store/ui-store';
import { useServicesStore } from '@/store/services-store';
import { Collection } from '@/store/common/collections';

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof ResourceMixin> &
    InstanceType<typeof BaseServiceMixin>
>).extend({
  components: {
    ServiceForm,
    BackButtonTitle
  },
  mixins: [ResourceMixin, BaseServiceMixin],
  props: {
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapStores(useUiStore, useServicesStore)
  },
  methods: {
    closeCurrentPanel() {
      this.uiStore.closePanel(ServicePanel.ADD_SERVICE);
    },
    async addService({
      service,
      preferredEmployees,
      notPreferredEmployees,
      machineModels,
      workplaces,
      childServices
    }) {
      const employeesForApi = this.getCollectionWithPreferencesForApi(
        preferredEmployees,
        notPreferredEmployees
      );
      const workplacesForApi = this.getCollectionForApi(workplaces);
      const machineModelsForApi = this.getCollectionForApi(machineModels);
      const childServicesForApi = this.getCollectionForApi(childServices);

      service = {
        ...service,
        employees: employeesForApi,
        machine_models: machineModelsForApi,
        workplaces: workplacesForApi,
        child_services: childServicesForApi
      };

      await this.servicesStore.create(service, [
        Collection.WORKPLACES,
        Collection.EMPLOYEES,
        Collection.MACHINE_MODELS
      ]);

      if (
        !this.sendingData &&
        Object.keys(this.validationErrors).length === 0
      ) {
        this.closeCurrentPanel();
      }
      if (!this.withAssociations) {
        this.uiStore.addNotification(Collection.SERVICES);
      }
    }
  }
});
