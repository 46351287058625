var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFetchingLocations && _vm.isFetchingWorkplaces)?_c('loading-indicator'):_vm._e(),(!_vm.isFetchingLocations && !_vm.isFetchingWorkplaces)?_c('div',[_c('search',{attrs:{"done-typing-interval":0,"default-value":_vm.searchTerms,"placeholder":"Nom","icon":"fas fa-filter"},on:{"onSearch":function($event){_vm.searchTerms = $event}}}),(_vm.workplaces.length === 0)?_c('empty-dataset',{attrs:{"text":"Aucun espace de travail","icon":"door-open"}}):_vm._e(),_vm._l((_vm.locations),function(location){return _c('div',{key:location.id},[(
          _vm.getWorkplacesFiltered(_vm.getWorkplacesForLocationId(location.id))
            .length > 0
        )?_c('div',[_c('h5',{staticClass:"tw-text-sm tw-pt-1 tw-pb-3 tw-uppercase tw-font-normal tw-font"},[_vm._v(" "+_vm._s(location.name)+" ")]),_vm._l((_vm.getWorkplacesFiltered(
            _vm.getWorkplacesForLocationId(location.id)
          )),function(workplace){return _c('div',{key:workplace.id,class:{
            active: _vm.currentWorkplace && _vm.currentWorkplace.id === workplace.id
          },on:{"click":function($event){return _vm.editWorkplace(workplace)}}},[_c('config-list-element',{attrs:{"current-entity":_vm.currentWorkplace,"entity":workplace,"collection":_vm.COLLECTION.WORKPLACES}},[_vm._v(" "+_vm._s(workplace.name)+" ")])],1)})],2):_vm._e()])})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }