





















import Vue, { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
  props: {
    backButtonLink: {
      type: String,
      default: ''
    },
    title: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    smallTitle: {
      type: Boolean,
      default: false
    }
  }
});
