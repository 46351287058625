import ResourceMixin from '@/mixins/ResourceMixin.ts';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useEmployeesStore } from '@/store/employees-store';
import { Workplace } from '@/model/workplace';
import { Service } from '@/model/service';
import { useServicesStore } from '@/store/services-store';
import { Employee } from '@/model/employee';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ResourceMixin>
>).extend({
  mixins: [ResourceMixin],
  computed: {
    ...mapStores(useWorkplacesStore, useEmployeesStore, useServicesStore),
    currentWorkplace(): Workplace {
      return this.workplacesStore.currentEntity;
    },
    workplaces(): Workplace[] {
      return this.workplacesStore.entities;
    },
    services(): Service[] {
      return this.servicesStore.entities;
    },
    employees(): Employee[] {
      return this.employeesStore.entities;
    }
  }
});
