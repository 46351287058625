























































import TextInput from '@/components/shared/forms/TextInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import BaseResourceFormMixin from '@/components/shared/BaseResourceFormMixin';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import { ValidationObserver } from 'vee-validate';
import Vue, { PropType, VueConstructor } from 'vue';
import { Machine } from '@/model/machine';
import { mapStores } from 'pinia';
import { useMachinesStore } from '@/store/machines-store';
import { InputOption } from '@/model/input-option';
import { useLocationsStore } from '@/store/locations-store';
import { useMachineModelsStore } from '@/store/machine-models-store';
import ButtonGroup from '@/components/shared/ButtonGroup.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseResourceFormMixin>
>).extend({
  components: {
    SelectInput,
    TextInput,
    SubmitButton,
    ValidationObserver,
    ButtonGroup
  },
  mixins: [BaseResourceFormMixin],
  props: {
    machineToEdit: {
      type: Object as PropType<Machine>,
      default: () => {}
    },
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      parseInt: parseInt,
      machine: {
        location_id: null as number | null,
        machine_model_id: null as number | null
      } as Partial<Machine>
    };
  },
  computed: {
    ...mapStores(useMachinesStore, useLocationsStore, useMachineModelsStore),
    defaultModelId(): number | null {
      return this.machineModelsStore.machineModelIdToAdd;
    },
    validationErrors(): any {
      return this.machinesStore.validationErrors;
    },
    savingMachine(): boolean {
      return this.machinesStore.saving;
    },
    machines(): Machine[] {
      return this.machinesStore.entities;
    },
    locationsOptions(): InputOption[] {
      return this.locationsStore.getLocationsAsSelectOptions;
    },
    machineModelsOptions(): InputOption[] {
      return this.machineModelsStore.getAsSelectOptions;
    }
  },
  watch: {
    machineToEdit() {
      this.fillExistingData();
    },
    defaultModelId() {
      this.fillExistingData();
    }
  },
  created() {
    this.fillExistingData();
  },
  methods: {
    fillExistingData() {
      this.machine = {
        id: this.machineToEdit ? this.machineToEdit.id : null,
        name: this.machineToEdit ? this.machineToEdit.name : '',
        machine_model_id: this.machineToEdit
          ? this.machineToEdit.machine_model_id
          : this.defaultModelId
          ? this.defaultModelId
          : null,
        location_id: this.machineToEdit ? this.machineToEdit.location_id : null
      };
      this.fillExistingAssociatedData(this.machineToEdit);
    },
    onSubmit(form) {
      form.validate();
      if (!form.valid) return;
      this.$emit('onFormValidated', {
        machine: this.machine
      });
    }
  }
});
