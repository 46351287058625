var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"mb-4",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('text-input',{attrs:{"value":_vm.service.name,"errors":_vm.validationErrors,"name":"name","label":_vm.$t('label.name')},on:{"onValueChange":function (value) {
        _vm.service.name = value;
      }}}),_c('select-input',{attrs:{"value":_vm.service.category_id,"errors":_vm.validationErrors,"options":_vm.categoryIdOptions,"name":"category_id","label":_vm.$t('label.category')},on:{"onValueChange":function (value) {
        _vm.service.category_id = value;
      }}}),_c('text-input',{attrs:{"value":_vm.service.no,"errors":_vm.validationErrors,"name":"no","label":_vm.$t('label.number')},on:{"onValueChange":function (value) {
        _vm.service.no = value;
      }}}),_c('textarea-input',{attrs:{"default-value":_vm.service.description,"errors":_vm.validationErrors,"name":"description","label":_vm.$t('label.description'),"rows":5},on:{"onValueChange":function (value) {
        _vm.service.description = value;
      }}}),_c('text-input',{attrs:{"value":_vm.service.price,"disabled":_vm.service.id !== '',"errors":_vm.validationErrors,"name":"price","prepend-text":"CHF","label":_vm.$t('label.price'),"help-text":!_vm.serviceToEdit ? _vm.$t('serviceForm.priceCantBeModified') : ''},on:{"onValueChange":function (value) {
        _vm.service.price = value;
      }}}),_c('text-input',{attrs:{"value":_vm.service.duration,"errors":_vm.validationErrors,"name":"duration","append-text":"mn","label":_vm.$t('label.baseDuration'),"help-text":_vm.$t('helpText.serviceDuration')},on:{"onValueChange":function (value) {
        _vm.service.duration = value;
      }}}),(
      _vm.serviceToEdit &&
        _vm.serviceToEdit.duration !== parseInt(_vm.service.duration, 10)
    )?_c('small',{staticClass:"d-block text-danger"},[_vm._v(_vm._s(_vm.$t('serviceForm.duration.currentBookingsWillBeKept')))]):_vm._e(),_c('checkbox-input',{attrs:{"value":_vm.service.fixed_duration,"errors":_vm.validationErrors,"name":"fixed_duration","label":_vm.$t('label.fixedDuration'),"help-text":_vm.$t('helpText.fixedDuration')},on:{"onValueChange":function (value) {
        _vm.service.fixed_duration = value;
      }}}),_c('div',{staticClass:"tw-border-t tw-pt-4 tw-flex tw-justify-between tw-items-center tw-cursor-pointer tw-text-primary-500 tw-mb-4",on:{"click":function($event){_vm.advance = !_vm.advance}}},[_c('span',[_vm._v(_vm._s(_vm.$t('serviceForm.advanced.title')))]),_c('i',{class:[_vm.advance ? 'fa fa-chevron-up' : 'fa fa-chevron-down']})]),(_vm.advance)?_c('div',{staticClass:"tw-my-4"},[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('span',{staticClass:"tw-mb-2 tw-text-medium tw-text-sm"},[_vm._v(_vm._s(_vm.$t('serviceForm.advanced.parts.title')))])]),(_vm.service.service_parts.length === 0)?_c('div',{staticClass:"tw-mt-1 tw-text-primary-500 tw-text-xs tw-font-bold tw-cursor-pointer",on:{"click":_vm.newServiceParts}},[_vm._v(" "+_vm._s(_vm.$t('serviceForm.advanced.parts.add'))+" "),_c('i',{staticClass:"fa fa-plus tw-ml-1"})]):_c('div',[_vm._l((_vm.serviceParts),function(part,index){return _c('div',{key:index},[_c('div',{staticClass:"lg:tw-flex lg:tw-justify-between lg:tw-items-center lg:tw-gap-3"},[_c('text-input',{attrs:{"value":part.start,"errors":_vm.validationErrors,"disabled":index === 0,"name":("service_parts." + index + ".start"),"append-text":"mn","label":_vm.$t('label.servicePart.start')},on:{"onValueChange":function (value) {
                part.start = value;
                _vm.updateServiceParts(index, value);
              }}}),_c('text-input',{attrs:{"value":part.duration,"errors":_vm.validationErrors,"disabled":index === _vm.service.service_parts.length - 1,"name":("service_parts." + index + ".duration"),"append-text":"mn","label":_vm.$t('label.servicePart.duration')},on:{"onValueChange":function (value) {
                part.duration = value;
              }}}),_c('i',{staticClass:"tw-mt-2 far fa-trash tw-text-medium tw-cursor-pointer",class:[
              index !== 0 && index !== _vm.service.service_parts.length - 1
                ? 'tw-visible'
                : 'tw-invisible'
            ],on:{"click":function($event){return _vm.removeServicePart(index)}}})],1),(index !== _vm.service.service_parts.length - 1)?_c('div',{staticClass:"tw-mt-0 tw-mb-2 tw-text-primary-500 tw-text-xs tw-font-bold tw-cursor-pointer",on:{"click":function($event){return _vm.addServicePart(index)}}},[_vm._v(" "+_vm._s(_vm.$t('serviceForm.advanced.parts.addInter'))+" "),_c('i',{staticClass:"fa fa-plus tw-ml-1"})]):_vm._e()])}),_c('span',{staticClass:"tw-text-medium tw-text-xs tw-font-bold tw-cursor-pointer",on:{"click":_vm.removeServiceParts}},[_vm._v(_vm._s(_vm.$t('serviceForm.advanced.parts.delete'))),_c('i',{staticClass:"far fa-trash tw-ml-1"})])],2)]):_vm._e(),(_vm.withAssociations)?_c('div',{staticClass:"border-top pt-4"},[_c('div',{staticClass:"mb-3"},[_c('multi-select-input',{attrs:{"label":_vm.$t('serviceForm.preferredEmployees.label'),"label-tooltip":_vm.$t('serviceForm.preferredEmployees.labelTooltip'),"track-by":"id","options":_vm.availablePreferredEmployees,"default-value":_vm.preferredEmployees,"custom-label":function (ref) {
                var firstname = ref.firstname;
                var lastname = ref.lastname;

                return firstname + ' ' + lastname;
}},on:{"onValueChange":function (value) {
            _vm.preferredEmployees = value;
          }}}),(_vm.removedPreferredEmployees.length > 0)?_c('small',{staticClass:"d-block text-danger"},[_vm._v(" "+_vm._s(_vm.$t('serviceForm.preferredEmployees.currentBookingsWillBeKept'))+" ")]):_vm._e(),_c('plain-button',{attrs:{"icon":"plus","size":"xs","button-link":"","right":"","text-color":"primary-500"},nativeOn:{"click":function($event){return _vm.addEmployee(1)}}},[_vm._v(" "+_vm._s(_vm.$t('button.createNewEmployee'))+" ")])],1),_c('div',{staticClass:"mb-3"},[_c('multi-select-input',{attrs:{"label":_vm.$t('serviceForm.notPreferredEmployees.label'),"label-tooltip":_vm.$t('serviceForm.notPreferredEmployees.labelTooltip'),"track-by":"id","options":_vm.availableNotPreferredEmployees,"default-value":_vm.notPreferredEmployees,"custom-label":function (ref) {
              var firstname = ref.firstname;
              var lastname = ref.lastname;

              return firstname + ' ' + lastname;
}},on:{"onValueChange":function (value) {
            _vm.notPreferredEmployees = value;
          }}}),(_vm.removedNonPreferredEmployees.length > 0)?_c('small',{staticClass:"d-block text-danger"},[_vm._v(" Si des RDV ont été pris avec cet employé et ces services, ils seront gardés. ")]):_vm._e(),_c('plain-button',{attrs:{"icon":"plus","size":"xs","button-link":"","right":"","text-color":"primary-500"},nativeOn:{"click":function($event){return _vm.addEmployee(0)}}},[_vm._v(" "+_vm._s(_vm.$t('button.createNewEmployee'))+" ")])],1),(_vm.vendor.workplaces_enabled)?_c('div',{staticClass:"mb-3"},[_c('multi-select-input',{attrs:{"label":_vm.$t('serviceForm.workplaces.label'),"track-by":"id","options":_vm.workplaces,"default-value":_vm.associatedWorkplaces,"custom-label":function (ref) {
              var name = ref.name;
              var location = ref.location;

              return name + ' (' + location.short_name + ')';
}},on:{"onValueChange":function (value) {
            _vm.associatedWorkplaces = value;
          }}}),_c('plain-button',{attrs:{"icon":"plus","size":"xs","button-link":"","right":"","text-color":"primary-500"},nativeOn:{"click":function($event){return _vm.addWorkplace()}}},[_vm._v(" "+_vm._s(_vm.$t('button.createNewWorkplace'))+" ")])],1):_vm._e(),(_vm.vendor.machines_enabled)?_c('div',{staticClass:"mb-4"},[_c('multi-select-input',{attrs:{"label":_vm.$t('serviceForm.machines.label'),"track-by":"id","options":_vm.machineModels,"multiple":false,"default-value":_vm.associatedMachineModels,"custom-label":function (ref) {
              var name = ref.name;

              return name;
}},on:{"onValueChange":function (value) {
            _vm.associatedMachineModels = value;
          }}}),_c('plain-button',{attrs:{"icon":"plus","size":"xs","button-link":"","right":"","text-color":"primary-500"},nativeOn:{"click":function($event){return _vm.addMachine()}}},[_vm._v(" "+_vm._s(_vm.$t('button.createNewResource'))+" ")])],1):_vm._e(),_c('div',{staticClass:"mb-3"},[_c('multi-select-input',{attrs:{"label":_vm.$t('serviceForm.associatedServices.label'),"label-tooltip":_vm.$t('serviceForm.associatedServices.labelTooltip'),"track-by":"id","options":_vm.servicesOptionsForChildServices,"default-value":_vm.childServices,"custom-label":function (ref) {
              var no = ref.no;
              var name = ref.name;

              return no + '. ' + name;
}},on:{"onValueChange":function (value) {
            _vm.childServices = value;
          }}})],1)]):_vm._e(),_c('button-group',[_c('submit-button',{attrs:{"loading":_vm.sendingData,"label":_vm.service.id ? _vm.$t('button.save') : _vm.$t('button.add')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }