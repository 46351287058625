




























































































































































































































































































































































import TextInput from '@/components/shared/forms/TextInput.vue';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import MultiSelectInput from '@/components/shared/forms/MultiSelectInput.vue';
import { Role } from '@/model/role.ts';
import { Gender } from '@/model/gender.ts';
import find from 'lodash/find';
import RadioInput from '@/components/shared/forms/RadioInput.vue';
import differenceWith from 'lodash/differenceWith';
import difference from 'lodash/difference';
import timetableHelpers from '@/helpers/timetable.helper';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';
import EmployeeLocationsConflicts from '@/components/employees/EmployeeLocationsConflicts.vue';
import CheckboxInput from '@/components/shared/forms/CheckboxInput.vue';
import Vue, { PropType } from 'vue';
import { Employee } from '@/model/employee';
import { Timetable } from '@/model/timetable';
import { TimetableDateException } from '@/model/timetable-date-exception';
import { Service } from '@/model/service';
import { Workplace } from '@/model/workplace';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useServicesStore } from '@/store/services-store';
import { useVendorsStore } from '@/store/vendors-store';
import {
  EmployeePanel,
  EMPLOYEES_PANELS_LAYOUT,
  useUiStore
} from '@/store/ui-store';
import { useUsersStore } from '@/store/users-store';
import { Vendor } from '@/model/vendor';
import { User } from '@/model/user';
import ButtonGroup from '@/components/shared/ButtonGroup.vue';
import PlainButton from '@/components/shared/PlainButton.vue';

export default Vue.extend({
  components: {
    CheckboxInput,
    EmployeeLocationsConflicts,
    RadioInput,
    TextInput,
    SubmitButton,
    SelectInput,
    MultiSelectInput,
    ButtonGroup,
    PlainButton
  },
  props: {
    employeeToEdit: {
      type: Object as PropType<Employee>,
      default: () => null
    },
    employeeTimetables: {
      type: Array as PropType<Timetable[]>,
      default: () => []
    },
    employeeTimetableExceptions: {
      type: Array as PropType<TimetableDateException[]>,
      default: () => []
    },
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      GENDER: Gender,
      employee: {
        username: '',
        email: '',
        gender: Gender.OTHER,
        lastname: '',
        firstname: '',
        role: Role.STAFF,
        timetable: false,
        can_set_timetable: false,
        out_of_schedule_events: false
      } as Partial<Employee> & Partial<User>,
      roleOptions: [
        {
          value: Role.STAFF.toString(),
          label: 'Staff'
        },
        {
          value: Role.ADMIN.toString(),
          label: 'Admin'
        }
      ],
      preferredServices: [] as Service[],
      notPreferredServices: [] as Service[],
      preferredWorkplaces: [] as Workplace[],
      notPreferredWorkplaces: [] as Workplace[],
      addingWorkplacePreference: null as any,
      addingServicePreference: null as any,
      unsubscribeFromServicesStore: null as any,
      unsubscribeFromWorkplacesStore: null as any
    };
  },
  computed: {
    ...mapStores(
      useEmployeesStore,
      useWorkplacesStore,
      useServicesStore,
      useVendorsStore,
      useUsersStore,
      useUiStore
    ),
    savingEmployee(): boolean {
      return this.employeesStore.saving;
    },
    errors(): any {
      return this.employeesStore.validationErrors;
    },
    workplaces(): Workplace[] {
      return this.workplacesStore.entities;
    },
    services(): Service[] {
      return this.servicesStore.entities;
    },
    vendor(): Vendor {
      return this.vendorsStore.currentEntity;
    },
    loggedInEmployeeId(): number {
      return this.usersStore.loggedInEmployeeId as number;
    },
    dataModelForApi(): any {
      const employee = {
        ...this.employee
      };
      if (this.employeeToEdit) {
        employee.id = this.employeeToEdit.id;
      }
      return employee;
    },
    availablePreferredServices(): Service[] {
      return this.services.filter(service => {
        return !find(this.notPreferredServices, { id: service.id });
      });
    },
    availableNotPreferredServices(): Service[] {
      return this.services.filter(service => {
        return !find(this.preferredServices, { id: service.id });
      });
    },
    availablePreferredWorkplaces(): Workplace[] {
      return this.workplaces.filter(workplace => {
        return !find(this.notPreferredWorkplaces, { id: workplace.id });
      });
    },
    availableNotPreferredWorkplaces(): Workplace[] {
      return this.workplaces.filter(workplace => {
        return !find(this.preferredWorkplaces, { id: workplace.id });
      });
    },
    preferredRemovedServices(): Service[] {
      return differenceWith(
        this.employeeToEdit
          ? this.employeeToEdit.services.filter(
              // @ts-ignore
              service => service._joinData.preference === 1
            )
          : [],
        [...this.preferredServices, ...this.notPreferredServices],
        (a, b) => a.id === b.id
      );
    },
    nonPreferredRemovedServices(): Service[] {
      return differenceWith(
        this.employeeToEdit
          ? this.employeeToEdit.services.filter(
              // @ts-ignore
              service => service._joinData.preference !== 1
            )
          : [],
        [...this.preferredServices, ...this.notPreferredServices],
        (a, b) => a.id === b.id
      );
    },
    preferredRemovedWorkplaces(): Workplace[] {
      return differenceWith(
        this.employeeToEdit
          ? this.employeeToEdit.workplaces.filter(
              // @ts-ignore
              workplace => workplace._joinData.preference === 1
            )
          : [],
        [...this.preferredWorkplaces, ...this.notPreferredWorkplaces],
        (a, b) => a.id === b.id
      );
    },
    nonPreferredRemovedWorkplaces(): Workplace[] {
      return differenceWith(
        this.employeeToEdit
          ? this.employeeToEdit.workplaces.filter(
              // @ts-ignore
              workplace => workplace._joinData.preference !== 1
            )
          : [],
        [...this.preferredWorkplaces, ...this.notPreferredWorkplaces],
        (a, b) => a.id === b.id
      );
    },
    workplacesTimetablesConflicts(): {
      hasConflicts: boolean;
      timetableConflicts?: Timetable[];
      timetableExceptionConflicts?: TimetableDateException[];
    } {
      if (!this.vendor.workplaces_enabled) {
        return { hasConflicts: false };
      }
      const timetablesByLocation = timetableHelpers.getTimetablesByLocation(
        this.employeeTimetables
      );
      const currentSelectedLocationIds = uniq(
        [...this.preferredWorkplaces, ...this.notPreferredWorkplaces].map(
          workplace => workplace.location_id
        )
      );
      const missingTimetableLocationIds = difference(
        Object.keys(timetablesByLocation).map(id => parseInt(id, 10)),
        currentSelectedLocationIds
      );
      const timetableConflicts = uniqWith(
        flatten(
          missingTimetableLocationIds.map(
            locationId => timetablesByLocation[locationId]
          )
        ),
        (a, b) => a.id === b.id
      );

      const timetableExceptionsByLocation = timetableHelpers.getTimetableExceptionsByLocation(
        this.employeeTimetableExceptions
      );
      const missingTimetableExceptionLocationIds = difference(
        Object.keys(timetableExceptionsByLocation).map(id => parseInt(id, 10)),
        currentSelectedLocationIds
      );
      const timetableExceptionConflicts = uniqWith(
        flatten(
          missingTimetableExceptionLocationIds.map(
            locationId => timetableExceptionsByLocation[locationId]
          )
        ),
        (a, b) => a.id === b.id
      );
      return {
        hasConflicts:
          timetableConflicts.length > 0 ||
          timetableExceptionConflicts.length > 0,
        timetableConflicts,
        timetableExceptionConflicts
      };
    },
    employeeIsAdmin(): boolean {
      return parseInt((this.employee.role as any) as string, 10) === Role.ADMIN;
    }
  },
  watch: {
    employeeToEdit() {
      this.fillExistingData();
    }
  },
  created() {
    this.unsubscribeFromServicesStore = this.servicesStore.$onAction(
      ({ name, args }) => {
        // A l'ajout d'un nouveau service, on l'ajoute directement au vue multiselect
        const newServiceAdded = name === 'create';
        if (newServiceAdded) {
          const service: Service = args[0] as Service;
          if (this.addingServicePreference === 1) {
            this.preferredServices.push(service);
          } else {
            this.notPreferredServices.push(service);
          }
        }
      }
    );
    this.unsubscribeFromWorkplacesStore = this.servicesStore.$onAction(
      ({ name, args }) => {
        // A l'ajout d'un nouvel espace de travail, on l'ajoute directement au vue multiselect
        const newWorkplaceAdded = name === 'create';
        if (newWorkplaceAdded) {
          const workplace: Workplace = args[0] as Workplace;
          this.preferredWorkplaces.push(workplace);
        }
      }
    );
    this.fillExistingData();
  },
  destroyed() {
    this.unsubscribeFromServicesStore();
    this.unsubscribeFromWorkplacesStore();
  },
  methods: {
    addWorkplace(preference) {
      this.uiStore.openPanel(
        EMPLOYEES_PANELS_LAYOUT,
        EmployeePanel.ADD_WORKPLACE
      );
      this.addingWorkplacePreference = preference;
    },
    addService(preference) {
      this.uiStore.openPanel(
        EMPLOYEES_PANELS_LAYOUT,
        EmployeePanel.ADD_SERVICE
      );
      this.addingServicePreference = preference;
    },
    onSubmit() {
      this.$emit('onFormValidated', {
        employee: this.dataModelForApi,
        preferredServices: this.preferredServices,
        notPreferredServices: this.notPreferredServices,
        preferredWorkplaces: this.preferredWorkplaces,
        notPreferredWorkplaces: this.notPreferredWorkplaces
      });
    },
    fillExistingData() {
      this.employee = {
        username:
          this.employeeToEdit &&
          this.employeeToEdit.user &&
          this.employeeToEdit.user.username
            ? this.employeeToEdit.user.username
            : '',
        email:
          this.employeeToEdit &&
          this.employeeToEdit.user &&
          this.employeeToEdit.user.email
            ? this.employeeToEdit.user.email
            : '',
        gender:
          this.employeeToEdit &&
          this.employeeToEdit.user &&
          this.employeeToEdit.user.gender
            ? this.employeeToEdit.user.gender
            : Gender.OTHER,
        lastname:
          this.employeeToEdit &&
          this.employeeToEdit.user &&
          this.employeeToEdit.user.lastname
            ? this.employeeToEdit.user.lastname
            : '',
        firstname:
          this.employeeToEdit &&
          this.employeeToEdit.user &&
          this.employeeToEdit.user.firstname
            ? this.employeeToEdit.user.firstname
            : '',
        role:
          this.employeeToEdit &&
          this.employeeToEdit.user &&
          this.employeeToEdit.user.role,
        timetable: this.employeeToEdit && this.employeeToEdit.timetable,
        can_set_timetable: this.employeeToEdit
          ? this.employeeToEdit.can_set_timetable
          : false,
        events_out_of_schedule: this.employeeToEdit
          ? this.employeeToEdit.events_out_of_schedule
          : false
      };
      this.fillExistingAssociatedData(this.employeeToEdit);
    },
    fillExistingAssociatedData(employee: Employee) {
      this.preferredWorkplaces =
        employee && employee.workplaces
          ? // @ts-ignore
            employee.workplaces.filter(service => service._joinData.preference)
          : [];
      this.notPreferredWorkplaces =
        employee && employee.workplaces
          ? // @ts-ignore
            employee.workplaces.filter(service => !service._joinData.preference)
          : [];
      this.preferredServices =
        employee && employee.services
          ? // @ts-ignore
            employee.services.filter(service => service._joinData.preference)
          : [];
      this.notPreferredServices =
        employee && employee.services
          ? // @ts-ignore
            employee.services.filter(service => !service._joinData.preference)
          : [];
    }
  }
});
