import { Color } from '@/constants';

export class ColorHelper {
  // Tiré de http://alienryderflex.com/hsp.html
  static isColorLight(color: Color, hue: number = 500): boolean {
    if (color === Color.YELLOW || color === Color.GREEN) {
      return hue <= 700;
    } else {
      return hue <= 300;
    }
  }
}
