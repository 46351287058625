


















































import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import WorkplaceForm from '@/components/workplaces/WorkplaceForm.vue';
import { Collection } from '@/store/common/collections.ts';
import entitiesService from '@/services/entities.service';
import BaseWorkplaceMixin from '@/components/workplaces/BaseWorkplaceMixin';
import CheckEventsConflictsModal from '@/components/timetables/CheckEventsConflictsModal.vue';
import difference from 'lodash/difference';
import resourcesService from '@/services/resource.service';
import ResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { Workplace } from '@/model/workplace';
import { AlertType, useUiStore, WorkplacePanel } from '@/store/ui-store';
import PlainButton from '@/components/shared/PlainButton.vue';

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof BaseWorkplaceMixin> &
    InstanceType<typeof ResourceConfigMixin>
>).extend({
  components: {
    BackButtonTitle,
    CheckEventsConflictsModal,
    WorkplaceForm,
    PlainButton
  },
  mixins: [BaseWorkplaceMixin, ResourceConfigMixin],
  data: () => {
    return {
      workplaceBeingSaved: null
    };
  },
  computed: {
    ...mapStores(useUiStore, useWorkplacesStore),
    currentWorkplace(): Workplace {
      return this.workplacesStore.currentEntity;
    }
  },
  methods: {
    closeCurrentPanel() {
      this.uiStore.closePanel(WorkplacePanel.EDIT_WORKPLACE);
      this.workplacesStore.unsetCurrentEntity();
    },
    async editWorkplace() {
      const employeesForApi = this.getCollectionWithPreferencesForApi(
        this.workplaceBeingSaved.preferredEmployees,
        this.workplaceBeingSaved.notPreferredEmployees
      );
      const servicesForApi = this.getCollectionForApi(
        this.workplaceBeingSaved.services
      );

      this.workplaceBeingSaved.workplace = {
        ...this.workplaceBeingSaved.workplace,
        [Collection.EMPLOYEES]: employeesForApi,
        [Collection.SERVICES]: servicesForApi
      };

      await this.workplacesStore.edit(this.workplaceBeingSaved.workplace, [
        Collection.EMPLOYEES,
        Collection.SERVICES
      ]);
    },
    openDeleteWorkplaceModal() {
      this.$bvModal.show('check-events-conflicts-modal');
    },
    async verifyDeleteRequest() {
      await entitiesService.verifyDelete('workplaces', this.currentWorkplace);
    },
    async deleteRequest() {
      this.workplacesStore.delete(this.currentWorkplace);
      this.closeCurrentPanel();
      this.workplacesStore.unsetCurrentEntity();
      this.uiStore.alertSuccess(this.$t('resource.deleted'));
    },
    async submit({
      workplace,
      preferredEmployees,
      notPreferredEmployees,
      services
    }) {
      this.workplaceBeingSaved = {
        workplace,
        preferredEmployees,
        notPreferredEmployees,
        services
      };
      if (
        difference(
          this.currentWorkplace.services.map(service => service.id),
          services.map(service => service.id)
        ).length > 0
      ) {
        try {
          this.workplacesStore.saving = true;
          await this.verifySaveRequest();
          await this.editWorkplace();
        } catch (e) {
          this.workplacesStore.saving = false;
          this.$bvModal.show('check-events-conflicts-modal-edit-workplace');
        }
      } else {
        await this.editWorkplace();
      }
    },
    deleted() {
      this.closeCurrentPanel();
      this.uiStore.alert(this.$t('toast.workplaceDeleted'), AlertType.SUCCESS);
    },
    async verifySaveRequest() {
      const serviceIdsBeingRemoved = difference(
        this.currentWorkplace.services.map(service => service.id),
        this.workplaceBeingSaved.services.map(service => service.id)
      );
      await resourcesService.verifyRemoveServicesFromWorkplace(
        this.currentWorkplace.id,
        { serviceIds: serviceIdsBeingRemoved }
      );
    }
  }
});
