












import Vue from 'vue';

export default Vue.extend({
  props: {
    justify: {
      validator(value: any) {
        return value === 'start' || value === 'end';
      },
      default: 'end'
    }
  }
});
