import apiService from './api.service';

export default {
  async addChildServicesToService(serviceId, childServices) {
    return (
      await apiService.post(
        `/services/add-related-services/${serviceId}.json`,
        childServices
      )
    ).data.data;
  },
  async removeChildServicesFromService(serviceId, childServices) {
    return (
      await apiService.delete(
        `/services/remove-related-services/${serviceId}.json`,
        childServices
      )
    ).data.data;
  },
  async verifyClosedFrom(id, closedFrom) {
    const response = await apiService.post(
      `/locations/verify-close/${id}.json`,
      {
        closedFrom: closedFrom
      }
    );
    return response.data.data;
  },
  async closedFrom(id, closedFrom) {
    const response = await apiService.post(`/locations/close/${id}.json`, {
      closedFrom: closedFrom
    });
    return response.data.data;
  },
  async reopenLocation(id) {
    const response = await apiService.post(`/locations/open/${id}.json`);
    return response.data.data;
  },
  async verifyClosedDate(closedDate) {
    const response = await apiService.post(
      '/closed-dates/verify.json',
      closedDate
    );
    return response.data.data;
  },
  async verifyRemoveServicesFromWorkplace(workplaceId, serviceIds) {
    return (
      await apiService.post(
        `/workplaces/verify-remove-services/${workplaceId}.json`,
        serviceIds
      )
    ).data.data;
  },
  async setServiceEmployees(serviceId, payload) {
    return (
      await apiService.post(
        `/services/set-employees/${serviceId}.json`,
        payload
      )
    ).data.data;
  },
  async setServiceMachineModels(serviceId, payload) {
    return (
      await apiService.post(
        `/services/set-machine-models/${serviceId}.json`,
        payload
      )
    ).data.data;
  },
  async setServiceWorkplaces(serviceId, payload) {
    return (
      await apiService.post(
        `/services/set-workplaces/${serviceId}.json`,
        payload
      )
    ).data.data;
  },
  async setWorkplaceEmployees(workplaceId, payload) {
    return (
      await apiService.post(
        `/workplaces/set-employees/${workplaceId}.json`,
        payload
      )
    ).data.data;
  },
  async setWorkplaceServices(workplaceId, payload) {
    return (
      await apiService.post(
        `/workplaces/set-services/${workplaceId}.json`,
        payload
      )
    ).data.data;
  },
  async setMachineModelServices(machineModelId, payload) {
    return (
      await apiService.post(
        `/machine-models/set-services/${machineModelId}.json`,
        payload
      )
    ).data.data;
  },
  async findAClosedDateByDate(date) {
    return (await apiService.get(`/closed-dates/get-by-date/${date}.json`)).data
      .data;
  }
};
