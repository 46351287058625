







































import Vue, { PropType } from 'vue';
import { Color } from '@/constants';
import { ColorHelper } from '@/helpers/color-helper';

export default Vue.extend({
  props: {
    color: {
      type: String as PropType<Color>,
      default: ''
    },
    hue: {
      type: Number,
      default: 500
    },
    colorSwatch: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      hueOptions: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      colorOptions: Color,
      Colors: Color
    };
  },
  computed: {
    isTextLight(): boolean {
      return ColorHelper.isColorLight(this.color as Color, this.hue);
    }
  },
  methods: {
    selectColor(selectedColor, selectedHue) {
      const hue: number = this.colorSwatch ? 500 : selectedHue;
      const color: Color = this.colorSwatch ? selectedColor : this.color;

      this.$emit('onSelected', { color, hue });
    }
  }
});
