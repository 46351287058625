import Vue from 'vue';
import { mapStores } from 'pinia';
import { useServicesStore } from '@/store/services-store';
import { Service } from '@/model/service';

export default Vue.extend({
  computed: {
    ...mapStores(useServicesStore),
    currentService(): Service {
      return this.servicesStore.currentEntity;
    },
    validationErrors(): {} {
      return this.servicesStore.validationErrors;
    },
    sendingData(): boolean {
      return this.servicesStore.saving;
    }
  }
});
