







































































































































import TextInput from '@/components/shared/forms/TextInput.vue';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import MultiSelectInput from '@/components/shared/forms/MultiSelectInput.vue';
import ColorInput from '@/components/shared/forms/ColorInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import BaseResourceFormMixin from '@/components/shared/BaseResourceFormMixin.ts';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useServicesStore } from '@/store/services-store';
import { Workplace } from '@/model/workplace';
import { Service } from '@/model/service';
import {
  useUiStore,
  WorkplacePanel,
  WORKPLACES_PANELS_LAYOUT
} from '@/store/ui-store';
import { InputOption } from '@/model/input-option';
import { useLocationsStore } from '@/store/locations-store';
import ButtonGroup from '@/components/shared/ButtonGroup.vue';
import PlainButton from '@/components/shared/PlainButton.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseResourceFormMixin>
>).extend({
  components: {
    TextInput,
    SelectInput,
    ColorInput,
    SubmitButton,
    MultiSelectInput,
    ButtonGroup,
    PlainButton
  },
  mixins: [BaseResourceFormMixin],
  props: {
    workplaceToEdit: {
      type: Object,
      default: () => {}
    },
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      workplace: {} as Partial<Workplace>
    };
  },
  computed: {
    ...mapStores(
      useUiStore,
      useWorkplacesStore,
      useServicesStore,
      useLocationsStore
    ),
    workplaces(): Workplace[] {
      return this.workplacesStore.entities;
    },
    validationErrors(): {} {
      return this.workplacesStore.validationErrors;
    },
    savingWorkplace(): boolean {
      return this.workplacesStore.saving;
    },
    services(): Service[] {
      return this.servicesStore.entities;
    },
    locationIdOptions(): InputOption[] {
      return this.locationsStore.getLocationsAsSelectOptions;
    }
  },
  watch: {
    workplaceToEdit() {
      this.fillExistingData();
    }
  },
  created() {
    this.fillExistingData();
  },
  methods: {
    fillExistingData() {
      this.workplace = {
        id: this.workplaceToEdit ? this.workplaceToEdit.id : '',
        name: this.workplaceToEdit ? this.workplaceToEdit.name : '',
        color: this.workplaceToEdit ? this.workplaceToEdit.color : 'blue',
        hue: this.workplaceToEdit ? this.workplaceToEdit.hue : '',
        location_id: this.workplaceToEdit
          ? this.workplaceToEdit.location_id
          : ''
      };
      this.fillExistingAssociatedData(this.workplaceToEdit);
    },
    selectLocationColor(locationId) {
      const location = this.locationsStore.getById(locationId);
      this.workplace.color = location.color;
    },
    addEmployee(preference) {
      this.uiStore.openPanel(
        WORKPLACES_PANELS_LAYOUT,
        WorkplacePanel.ADD_EMPLOYEE
      );
      this.addingEmployeePreference = preference;
    },
    addMachine() {
      this.uiStore.openPanel(
        WORKPLACES_PANELS_LAYOUT,
        WorkplacePanel.ADD_MACHINE
      );
    },
    addService() {
      this.uiStore.openPanel(
        WORKPLACES_PANELS_LAYOUT,
        WorkplacePanel.ADD_SERVICE
      );
    },
    onSubmit() {
      this.$emit('onFormValidated', {
        workplace: this.workplace,
        preferredEmployees: this.preferredEmployees,
        notPreferredEmployees: this.notPreferredEmployees,
        services: this.associatedServices
      });
    }
  }
});
