import { Entity } from '@/model/entity';
import Vue from 'vue';

export default Vue.extend({
  methods: {
    getCollectionWithPreferencesForApi(
      preferredResources: any,
      notPreferredResources: any
    ) {
      const collectionWithPreferencesForApi: any[] = [];
      // Ajout des nouvelles / mise à jour si déjà existant
      if (preferredResources.length > 0) {
        preferredResources.forEach(resource => {
          collectionWithPreferencesForApi.push({
            id: resource.id,
            _joinData: {
              preference: 1
            }
          });
        });
      }
      if (notPreferredResources.length > 0) {
        notPreferredResources.forEach(resource => {
          collectionWithPreferencesForApi.push({
            id: resource.id,
            _joinData: {
              preference: 0
            }
          });
        });
      }

      return collectionWithPreferencesForApi;
    },
    getCollectionForApi(collectionArray: Entity[]) {
      const collectionForApi: any = { _ids: [] };
      if (collectionArray.length > 0) {
        collectionArray.forEach(collection => {
          collectionForApi._ids.push(collection.id);
        });
      }
      return collectionForApi;
    }
  }
});
