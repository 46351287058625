














































import FormInputMixin from '@/mixins/FormInputMixin';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof FormInputMixin>
>).extend({
  mixins: [FormInputMixin],
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: null,
      required: false
    },
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    modelValue: {
      type: Array,
      default: null
    },
    errors: {
      type: Object,
      default: () => {}
    },
    trueValue: {
      type: Boolean,
      default: true
    },
    falseValue: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    helpText: {
      type: String,
      default: ''
    }
  },
  computed: {
    shouldBeChecked(): boolean | string | number {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }

      return this.value;
      // return this.modelValue === this.trueValue;
    }
  },
  methods: {
    updateInput(event) {
      const isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit('onValueChange', newValue);
      } else {
        this.$emit(
          'onValueChange',
          isChecked ? this.trueValue : this.falseValue
        );
      }
    }
  }
});
