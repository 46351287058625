var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('text-input',{attrs:{"value":_vm.employee.username,"errors":_vm.errors,"name":"user.username","label":_vm.$t('label.username'),"optional":true,"help-text":_vm.$t('signUp.usernameHint')},on:{"onValueChange":function (value) {
        _vm.employee.username = value;
      }}}),_c('text-input',{attrs:{"value":_vm.employee.email,"errors":_vm.errors,"type":"email","name":"user.email","label":_vm.$t('label.email')},on:{"onValueChange":function (value) {
        _vm.employee.email = value;
      }}}),_c('hr',{staticClass:"mt-4"}),_c('radio-input',{attrs:{"default-value":_vm.employee.gender,"options":[
      { value: _vm.GENDER.FEMALE, label: _vm.$t('label.mrs') },
      { value: _vm.GENDER.MALE, label: _vm.$t('label.mr') },
      { value: _vm.GENDER.OTHER, label: _vm.$t('label.genderOther') }
    ],"errors":_vm.errors,"inline":true,"label":_vm.$t('label.gender'),"name":"user.gender"},on:{"onValueChange":function (value) {
        _vm.employee.gender = value;
      }}}),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-6"},[_c('text-input',{attrs:{"value":_vm.employee.firstname,"errors":_vm.errors,"name":"user.firstname","label":_vm.$t('label.firstname')},on:{"onValueChange":function (value) {
            _vm.employee.firstname = value;
          }}})],1),_c('div',{staticClass:"col-md-6"},[_c('text-input',{attrs:{"value":_vm.employee.lastname,"errors":_vm.errors,"name":"user.lastname","label":_vm.$t('label.lastname')},on:{"onValueChange":function (value) {
            _vm.employee.lastname = value;
          }}})],1)]),(_vm.isAdmin)?_c('select-input',{attrs:{"value":_vm.employee.role,"errors":_vm.errors,"options":_vm.roleOptions,"name":"user.role","label":_vm.$t('label.role')},on:{"onValueChange":function (value) {
        _vm.employee.role = value;
      }}}):_vm._e(),(_vm.isAdmin)?_c('radio-input',{attrs:{"default-value":_vm.employee.timetable,"value-data-type":"boolean","errors":_vm.errors,"inline":true,"options":[
      {
        value: '1',
        label: _vm.$t('label.enabled')
      },
      {
        value: '0',
        label: _vm.$t('label.disabled')
      }
    ],"name":"user.timetable","label":_vm.$t('label.timetablesEnabled')},on:{"onValueChange":function (value) {
        _vm.employee.timetable = value;
      }}}):_vm._e(),(_vm.isAdmin && _vm.employee.timetable)?_c('div',{staticClass:"bg-light p-2 rounded timetable-options mb-4"},[_c('checkbox-input',{attrs:{"label":_vm.$t('label.canSetOwnTimetables'),"name":"can_set_timetable","disabled":_vm.employeeIsAdmin,"value":_vm.employeeIsAdmin ? true : _vm.employee.can_set_timetable},on:{"onValueChange":function (value) {
          _vm.employee.can_set_timetable = value;
        }}}),_c('checkbox-input',{attrs:{"label":_vm.$t('label.canCreateBookingsOutOfTimetables'),"name":"user.events_out_of_schedule","value":_vm.employee.events_out_of_schedule},on:{"onValueChange":function (value) {
          _vm.employee.events_out_of_schedule = value;
        }}})],1):_vm._e(),(_vm.withAssociations && _vm.employee.timetable)?_c('div',{staticClass:"border-top mt-4 pt-4"},[_c('div',{staticClass:"mb-3"},[_c('multi-select-input',{attrs:{"label":_vm.$t('employeeForm.preferredServices.label'),"label-tooltip":_vm.$t('employeeForm.preferredServices.labelTooltip'),"track-by":"id","options":_vm.availablePreferredServices,"default-value":_vm.preferredServices,"custom-label":function (ref) {
            var no = ref.no;
            var name = ref.name;

            return no + '. ' + name;
}},on:{"onValueChange":function (value) {
            _vm.preferredServices = value;
          }}}),(_vm.preferredRemovedServices.length > 0)?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" "+_vm._s(_vm.$t('employeeForm.removedServicesWarning'))+" "),_c('ul',_vm._l((_vm.preferredRemovedServices),function(service){return _c('li',{key:service.id},[_vm._v(" "+_vm._s(service.name)+" ")])}),0)]):_vm._e(),_c('plain-button',{attrs:{"icon":"plus","size":"xs","button-link":"","right":"","text-color":"primary-500"},nativeOn:{"click":function($event){return _vm.addService(1)}}},[_vm._v(" "+_vm._s(_vm.$t('button.createNewService'))+" ")])],1),_c('div',{staticClass:"mb-3"},[_c('multi-select-input',{attrs:{"label":_vm.$t('employeeForm.nonPreferredServices.label'),"label-tooltip":_vm.$t('employeeForm.nonPreferredServices.labelTooltip'),"track-by":"id","options":_vm.availableNotPreferredServices,"default-value":_vm.notPreferredServices,"custom-label":function (ref) {
              var no = ref.no;
              var name = ref.name;

              return no + '. ' + name;
}},on:{"onValueChange":function (value) {
            _vm.notPreferredServices = value;
          }}}),(_vm.nonPreferredRemovedServices.length > 0)?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" "+_vm._s(_vm.$t('employeeForm.removedServicesWarning'))+" "),_c('ul',_vm._l((_vm.nonPreferredRemovedServices),function(service){return _c('li',{key:service.id},[_vm._v(" "+_vm._s(service.name)+" ")])}),0)]):_vm._e(),_c('plain-button',{attrs:{"icon":"plus","size":"xs","button-link":"","right":"","text-color":"primary-500"},nativeOn:{"click":function($event){return _vm.addService(0)}}},[_vm._v(" "+_vm._s(_vm.$t('button.createNewService'))+" ")])],1),(_vm.vendor.workplaces_enabled)?_c('div',[_c('div',{staticClass:"mb-3"},[_c('multi-select-input',{attrs:{"label":_vm.$t('employeeForm.preferredWorkplaces.label'),"label-tooltip":_vm.$t('employeeForm.preferredWorkplaces.labelTooltip'),"track-by":"id","options":_vm.availablePreferredWorkplaces,"default-value":_vm.preferredWorkplaces,"custom-label":function (ref) {
              var name = ref.name;
              var location = ref.location;

              return name + ' (' + location.short_name + ')';
}},on:{"onValueChange":function (value) {
              _vm.preferredWorkplaces = value;
            }}}),(
            _vm.preferredRemovedWorkplaces.length > 0 &&
              !_vm.workplacesTimetablesConflicts.hasConflicts
          )?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" "+_vm._s(_vm.$t('employeeForm.removedWorkplacesWarning'))+" "),_c('ul',_vm._l((_vm.preferredRemovedWorkplaces),function(workplace){return _c('li',{key:workplace.id},[_vm._v(" "+_vm._s(workplace.name)+" ("+_vm._s(workplace.location.short_name)+") ")])}),0)]):_vm._e(),(
            _vm.preferredRemovedWorkplaces.length > 0 &&
              _vm.workplacesTimetablesConflicts.hasConflicts
          )?_c('employee-locations-conflicts',{attrs:{"workplaces-timetables-conflicts":_vm.workplacesTimetablesConflicts}}):_vm._e(),_c('plain-button',{attrs:{"icon":"plus","size":"xs","button-link":"","right":"","text-color":"primary-500"},nativeOn:{"click":function($event){return _vm.addWorkplace(1)}}},[_vm._v(" "+_vm._s(_vm.$t('button.createNewWorkplace'))+" ")])],1),_c('div',{staticClass:"mb-3"},[_c('multi-select-input',{attrs:{"label":_vm.$t('employeeForm.nonPreferredWorkplaces.label'),"label-tooltip":_vm.$t('employeeForm.nonPreferredWorkplaces.labelTooltip'),"track-by":"id","options":_vm.availableNotPreferredWorkplaces,"default-value":_vm.notPreferredWorkplaces,"custom-label":function (ref) {
              var name = ref.name;
              var location = ref.location;

              return name + ' (' + location.short_name + ')';
}},on:{"onValueChange":function (value) {
              _vm.notPreferredWorkplaces = value;
            }}}),(
            _vm.nonPreferredRemovedWorkplaces.length > 0 &&
              !_vm.workplacesTimetablesConflicts.hasConflicts
          )?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" "+_vm._s(_vm.$t('employeeForm.removedWorkplacesWarning'))+" "),_c('ul',_vm._l((_vm.nonPreferredRemovedWorkplaces),function(workplace){return _c('li',{key:workplace.id},[_vm._v(" "+_vm._s(workplace.name)+" ("+_vm._s(workplace.location.short_name)+") ")])}),0)]):_vm._e(),(
            _vm.nonPreferredRemovedWorkplaces.length > 0 &&
              _vm.workplacesTimetablesConflicts.hasConflicts
          )?_c('employee-locations-conflicts',{attrs:{"workplaces-timetables-conflicts":_vm.workplacesTimetablesConflicts}}):_vm._e(),_c('plain-button',{attrs:{"icon":"plus","size":"xs","button-link":"","right":"","text-color":"primary-500"},nativeOn:{"click":function($event){return _vm.addWorkplace(0)}}},[_vm._v(" "+_vm._s(_vm.$t('button.createNewWorkplace'))+" ")])],1)]):_vm._e()]):_vm._e(),_c('button-group',[(_vm.isAdmin || _vm.employeeToEdit.id === _vm.loggedInEmployeeId)?_c('submit-button',{attrs:{"disabled":(_vm.preferredRemovedWorkplaces.length > 0 ||
          _vm.nonPreferredRemovedWorkplaces.length > 0) &&
          _vm.workplacesTimetablesConflicts.hasConflicts,"loading":_vm.savingEmployee}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }