import find from 'lodash/find';
import Vue from 'vue';
import { Employee } from '@/model/employee';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { useServicesStore } from '@/store/services-store';
import { Service } from '@/model/service';
import { useMachineModelsStore } from '@/store/machine-models-store';
import { MachineModel } from '@/model/machine-model';
import { Workplace } from '@/model/workplace';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useRegionsStore } from '@/store/regions-store';

export default Vue.extend({
  data() {
    return {
      associatedMachineModels: [] as MachineModel[],
      associatedWorkplaces: [] as Workplace[],
      preferredEmployees: [] as Employee[],
      notPreferredEmployees: [] as Employee[],
      associatedServices: [] as Service[],
      addingEmployeePreference: null,
      unsubscribeFromStore: null
    };
  },
  computed: {
    ...mapStores(
      useEmployeesStore,
      useServicesStore,
      useMachineModelsStore,
      useWorkplacesStore,
      useRegionsStore
    ),
    employees(): Employee[] {
      return this.employeesStore.entities;
    },
    availablePreferredEmployees(): Employee[] {
      return this.employees.filter(employee => {
        return !find(this.notPreferredEmployees, { id: employee.id });
      });
    },
    availableNotPreferredEmployees(): Employee[] {
      return this.employees.filter(employee => {
        return !find(this.preferredEmployees, { id: employee.id });
      });
    }
  },
  created() {
    /* const entityEdited = mutation.type === `resources/${entitiesTypes.EDIT_ENTITY}`
      if (entityEdited) {
        this.$store.commit(
          `resources/${entitiesTypes.SET_CURRENT_ENTITY}`,
          {
            collectionName: mutation.payload.collectionName,
            entity: mutation.payload.entity
          }
        )
      } */
    // A l'ajout d'un nouveau service, on l'ajoute directement au vue multiselect
    this.servicesStore.$onAction(({ name, after }) => {
      if (name === 'create') {
        after(service => {
          this.associatedServices.push(service);
        });
      }
    });
    // A l'ajout d'un nouveau modèle de machine, on l'ajoute directement au vue multiselect
    this.machineModelsStore.$onAction(({ name, after }) => {
      if (name === 'create') {
        after(machineModel => {
          this.associatedMachineModels.push(machineModel);
        });
      }
    });
    // A l'ajout d'un nouvel espace de travail, on l'ajoute directement au vue multiselect
    this.workplacesStore.$onAction(({ name, after }) => {
      if (name === 'create') {
        after(workplace => {
          this.associatedWorkplaces.push(workplace);
        });
      }
    });
    // A l'ajout d'un nouvel employé, on l'ajoute directement au vue multiselect
    this.employeesStore.$onAction(({ name, after }) => {
      if (name === 'create') {
        after(employee => {
          if (this.addingEmployeePreference === 1) {
            this.preferredEmployees.push(employee);
          } else {
            this.notPreferredEmployees.push(employee);
          }
        });
      }
    });
  },
  methods: {
    fillExistingAssociatedData(entityToEdit: any) {
      this.associatedMachineModels =
        entityToEdit && entityToEdit.machine_models
          ? entityToEdit.machine_models
          : [];
      this.associatedWorkplaces =
        entityToEdit && entityToEdit.workplaces ? entityToEdit.workplaces : [];
      this.associatedServices =
        entityToEdit && entityToEdit.services ? entityToEdit.services : [];
      this.preferredEmployees =
        entityToEdit && entityToEdit.employees
          ? entityToEdit.employees.filter(
              employee => employee._joinData.preference
            )
          : [];
      this.notPreferredEmployees =
        entityToEdit && entityToEdit.employees
          ? entityToEdit.employees.filter(
              employee => !employee._joinData.preference
            )
          : [];
    }
  }
});
