


























import Vue from 'vue';

export default Vue.extend({
  props: {
    defaultValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Rechercher'
    },
    icon: {
      type: String,
      default: 'fas fa-search'
    },
    // Attendra 250ms avant d'émettre l'événement (à passer à 0 si c'est une recherche locale qui n'appelle pas l'API)
    doneTypingInterval: {
      type: Number,
      default: 250
    }
  },
  data() {
    return {
      typingTimer: null as any,
      search: this.defaultValue as string
    };
  },
  watch: {
    defaultValue(searchValue: string) {
      this.search = searchValue;
    }
  },
  methods: {
    onKeyDown() {
      clearTimeout(this.typingTimer);
    },
    onKeyUp(event) {
      if (!event) {
        this.onDoneTyping();
      } else if (event.keyCode !== 13) {
        // Submit == Enter
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {
          this.onDoneTyping();
        }, this.doneTypingInterval);
      } else if (event.keyCode === 13) {
        this.onDoneTyping();
      }
    },
    onDoneTyping() {
      this.$emit('onSearch', this.search);
    },
    emptyField() {
      this.search = '';
      this.onDoneTyping();
    }
  }
});
