





























































import WorkplacesList from '@/components/workplaces/WorkplacesList.vue';
import AddWorkplace from '@/components/workplaces/AddWorkplace.vue';
import EditWorkplace from '@/components/workplaces/EditWorkplace.vue';
import AddMachine from '@/components/machines/AddMachine.vue';
import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import EmployeeForm from '@/components/employees/EmployeeForm.vue';
import AddService from '@/components/services/AddService.vue';
import { Collection } from '@/store/common/collections.ts';
import Vue from 'vue';
import {
  useUiStore,
  WorkplacePanel,
  WORKPLACES_PANELS_LAYOUT
} from '@/store/ui-store';
import { mapStores } from 'pinia';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { Workplace } from '@/model/workplace';
import { useUsersStore } from '@/store/users-store';
import { useEmployeesStore } from '@/store/employees-store';
import PlainButton from '@/components/shared/PlainButton.vue';

export default Vue.extend({
  components: {
    WorkplacesList,
    AddWorkplace,
    EditWorkplace,
    AddMachine,
    EmployeeForm,
    BackButtonTitle,
    AddService,
    PlainButton
  },
  data() {
    return {
      WORKPLACE_PANEL: WorkplacePanel
    };
  },
  computed: {
    ...mapStores(
      useUiStore,
      useWorkplacesStore,
      useUsersStore,
      useEmployeesStore
    ),
    openedPanels(): string[] {
      return this.uiStore.openedPanels;
    },
    currentPanelIndex(): number {
      return this.uiStore.currentPanelIndex;
    },
    currentPanel(): string {
      return this.uiStore.currentPanel;
    },
    currentWorkplace(): Workplace {
      return this.workplacesStore.currentEntity;
    },
    vendorId(): number | null {
      return this.usersStore.loggedInVendorId;
    }
  },
  created() {
    this.workplacesStore.findAll(this.vendorId, true, false);

    this.uiStore.openPanel(
      WORKPLACES_PANELS_LAYOUT,
      WorkplacePanel.WORKPLACES_LIST
    );
    if (this.currentWorkplace.id) {
      this.uiStore.openPanel(
        WORKPLACES_PANELS_LAYOUT,
        WorkplacePanel.EDIT_WORKPLACE
      );
    }
  },
  methods: {
    isPanelOpened(panel) {
      return this.uiStore.isPanelOpened(panel);
    },
    addWorkplace() {
      this.workplacesStore.unsetCurrentEntity();
      this.uiStore.openPanel(
        WORKPLACES_PANELS_LAYOUT,
        WorkplacePanel.ADD_WORKPLACE
      );
    },
    closeAddEmployeePanel() {
      this.uiStore.closePanel(WorkplacePanel.ADD_EMPLOYEE);
    },
    async addEmployee(createEmployeeDto) {
      const employeeAdded = await this.employeesStore.create(createEmployeeDto);
      if (employeeAdded) {
        this.closeAddEmployeePanel();
        this.uiStore.addNotification(Collection.EMPLOYEES);
      }
    }
  }
});
