

















import WorkplaceForm from '@/components/workplaces/WorkplaceForm.vue';
import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import { Collection } from '@/store/common/collections.ts';
import BaseWorkplaceMixin from '@/components/workplaces/BaseWorkplaceMixin';
import { mapStores } from 'pinia';
import { useUiStore, WorkplacePanel } from '@/store/ui-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseWorkplaceMixin>
>).extend({
  components: {
    WorkplaceForm,
    BackButtonTitle
  },
  mixins: [BaseWorkplaceMixin],
  props: {
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapStores(useUiStore, useWorkplacesStore),
    sendingData(): boolean {
      return this.workplacesStore.saving;
    },
    validationErrors(): Object {
      return this.workplacesStore.validationErrors;
    }
  },
  methods: {
    closePanel() {
      this.uiStore.closePanel(WorkplacePanel.ADD_WORKPLACE);
    },
    async addWorkplace({
      workplace,
      preferredEmployees,
      notPreferredEmployees,
      services
    }) {
      const employeesForApi = this.getCollectionWithPreferencesForApi(
        preferredEmployees,
        notPreferredEmployees
      );
      const servicesForApi = this.getCollectionForApi(services);

      workplace = {
        ...workplace,
        [Collection.EMPLOYEES]: employeesForApi,
        [Collection.SERVICES]: servicesForApi
      };

      await this.workplacesStore.create(workplace);

      if (
        !this.sendingData &&
        Object.keys(this.validationErrors).length === 0
      ) {
        this.closePanel();
      }
      if (!this.withAssociations) {
        this.uiStore.addNotification(Collection.WORKPLACES);
      }
    }
  }
});
