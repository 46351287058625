














































import { flattenDeep, uniqWith } from 'lodash';
import Vue from 'vue';
import moment from 'moment';

export default Vue.extend({
  name: 'EmployeeLocationsConflicts',
  props: {
    workplacesTimetablesConflicts: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      moment: moment
    };
  },
  methods: {
    timetableLocations(timetable) {
      return uniqWith(
        flattenDeep(
          timetable.weekdays.map(weekday =>
            weekday.time_ranges.map(timeRange => timeRange.location)
          )
        ),
        (a, b) => a.id === b.id
      );
    },
    timetableExceptionLocations(timetableException) {
      return uniqWith(
        timetableException.time_range_exceptions.map(
          timeRange => timeRange.location
        ),
        (a, b) => a.id === b.id
      );
    }
  }
});
