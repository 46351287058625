
























import FormInputMixin from '@/mixins/FormInputMixin';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof FormInputMixin>
>).extend({
  mixins: [FormInputMixin],
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    defaultValue: {
      type: String,
      default: ''
    },
    helpText: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: 10
    },
    errors: {
      type: Object,
      default: () => {}
    },
    optional: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this.defaultValue
    };
  },
  watch: {
    defaultValue(defaultValue) {
      this.value = defaultValue;
    }
  },
  methods: {
    onInput(event) {
      this.$emit('onValueChange', event.target.value);
    }
  }
});
