var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"mb-4",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('text-input',{attrs:{"value":_vm.workplace.name,"errors":_vm.validationErrors,"name":"name","label":_vm.$t('label.name')},on:{"onValueChange":function (value) {
        _vm.workplace.name = value;
      }}}),_c('select-input',{attrs:{"value":_vm.workplace.location_id,"errors":_vm.validationErrors,"options":_vm.locationIdOptions,"name":"location_id","label":_vm.$t('label.location')},on:{"onValueChange":function (value) {
        _vm.workplace.location_id = value;
        _vm.selectLocationColor(value);
      }}}),_c('color-input',{attrs:{"color-swatch":false,"color":_vm.workplace.color,"hue":_vm.workplace.hue,"errors":_vm.validationErrors,"name":"color","label":_vm.$t('label.calendarColor')},on:{"onValueChange":function (ref) {
        var color = ref.color;
        var hue = ref.hue;

        _vm.workplace.color = color;
        _vm.workplace.hue = hue;
      }}}),(_vm.withAssociations)?_c('div',{staticClass:"border-top mt-4 pt-3"},[_c('div',{staticClass:"mb-3"},[_c('multi-select-input',{attrs:{"label":_vm.$t('workplaceForm.preferredEmployees.label'),"label-tooltip":_vm.$t('workplaceForm.preferredEmployees.labelTooltip'),"track-by":"id","options":_vm.availablePreferredEmployees,"default-value":_vm.preferredEmployees,"custom-label":function (ref) {
            var firstname = ref.firstname;
            var lastname = ref.lastname;

            return firstname + ' ' + lastname;
}},on:{"onValueChange":function (value) {
            _vm.preferredEmployees = value;
          }}}),_c('plain-button',{attrs:{"icon":"plus","size":"xs","button-link":"","right":"","text-color":"primary-500"},nativeOn:{"click":function($event){return _vm.addEmployee(1)}}},[_vm._v(" "+_vm._s(_vm.$t('button.createNewEmployee'))+" ")])],1),_c('div',{staticClass:"mb-3"},[_c('multi-select-input',{attrs:{"label":_vm.$t('workplaceForm.notPreferredEmployees.label'),"label-tooltip":_vm.$t('workplaceForm.notPreferredEmployees.labelTooltip'),"track-by":"id","options":_vm.availableNotPreferredEmployees,"default-value":_vm.notPreferredEmployees,"custom-label":function (ref) {
                var firstname = ref.firstname;
                var lastname = ref.lastname;

                return firstname + ' ' + lastname;
}},on:{"onValueChange":function (value) {
            _vm.notPreferredEmployees = value;
          }}}),_c('plain-button',{attrs:{"icon":"plus","size":"xs","button-link":"","right":"","text-color":"primary-500"},nativeOn:{"click":function($event){return _vm.addEmployee(0)}}},[_vm._v(" "+_vm._s(_vm.$t('button.createNewEmployee'))+" ")])],1),_c('div',{staticClass:"mb-3"},[_c('multi-select-input',{attrs:{"label":_vm.$t('workplaceForm.authorizedServices.label'),"track-by":"id","options":_vm.services,"default-value":_vm.associatedServices,"custom-label":function (ref) {
                var no = ref.no;
                var name = ref.name;

                return no + '. ' + name;
}},on:{"onValueChange":function (value) {
            _vm.associatedServices = value;
          }}}),_c('plain-button',{attrs:{"icon":"plus","size":"xs","button-link":"","right":"","text-color":"primary-500"},nativeOn:{"click":function($event){return _vm.addService()}}},[_vm._v(" "+_vm._s(_vm.$t('button.createNewService'))+" ")])],1)]):_vm._e(),_c('button-group',[_c('submit-button',{attrs:{"loading":_vm.savingWorkplace}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }