










































































































































































































































































































































import TextInput from '@/components/shared/forms/TextInput.vue';
import TextareaInput from '@/components/shared/forms/TextareaInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import MultiSelectInput from '@/components/shared/forms/MultiSelectInput.vue';
import BaseResourceFormMixin from '@/components/shared/BaseResourceFormMixin';
import differenceWith from 'lodash/differenceWith';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useServicesStore } from '@/store/services-store';
import { MachineModel } from '@/model/machine-model';
import { useMachineModelsStore } from '@/store/machine-models-store';
import { Workplace } from '@/model/workplace';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { Service } from '@/model/service';
import { useVendorsStore } from '@/store/vendors-store';
import { Vendor } from '@/model/vendor';
import {
  ServicePanel,
  SERVICES_PANELS_LAYOUT,
  useUiStore
} from '@/store/ui-store';
import { Employee } from '@/model/employee';
import { InputOption } from '@/model/input-option';
import { useCategoriesStore } from '@/store/categories-store';
import CheckboxInput from '@/components/shared/forms/CheckboxInput.vue';
import { ServiceParts } from '@/model/service-parts';
import ButtonGroup from '@/components/shared/ButtonGroup.vue';
import PlainButton from '@/components/shared/PlainButton.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseResourceFormMixin>
>).extend({
  components: {
    CheckboxInput,
    TextInput,
    TextareaInput,
    SubmitButton,
    SelectInput,
    MultiSelectInput,
    ButtonGroup,
    PlainButton
  },
  mixins: [BaseResourceFormMixin],
  props: {
    serviceToEdit: {
      type: Object,
      default: () => {}
    },
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      service: {} as Partial<Service>,
      childServices: [] as Service[],
      advance: false
    };
  },
  computed: {
    ...mapStores(
      useUiStore,
      useServicesStore,
      useMachineModelsStore,
      useWorkplacesStore,
      useVendorsStore,
      useCategoriesStore
    ),
    validationErrors(): {} {
      return this.servicesStore.validationErrors;
    },
    sendingData(): boolean {
      return this.servicesStore.saving;
    },
    machineModels(): MachineModel[] {
      return this.machineModelsStore.entities;
    },
    workplaces(): Workplace[] {
      return this.workplacesStore.entities;
    },
    services(): Service[] {
      return this.servicesStore.entities;
    },
    serviceParts(): ServiceParts[] {
      return this.service.service_parts.filter(
        parts => parts.attended === true
      );
    },
    servicesOptionsForChildServices(): Service[] {
      if (!this.serviceToEdit) {
        return [];
      }
      return this.servicesStore.entities.filter(
        service => this.serviceToEdit.id !== service.id
      );
    },
    vendor(): Vendor {
      return this.vendorsStore.currentEntity;
    },
    categoryIdOptions(): InputOption[] {
      return this.categoriesStore.getAsSelectOptions;
    },
    removedPreferredEmployees(): Employee[] {
      if (!this.serviceToEdit) {
        return [];
      }
      return differenceWith(
        this.serviceToEdit.employees.filter(
          employee => employee._joinData.preference === 1
        ),
        [...this.preferredEmployees, ...this.notPreferredEmployees],
        (a, b) => a.id === b.id
      );
    },
    removedNonPreferredEmployees(): Employee[] {
      if (!this.serviceToEdit) {
        return [];
      }
      return differenceWith(
        this.serviceToEdit.employees.filter(
          employee => employee._joinData.preference !== 1
        ),
        [...this.preferredEmployees, ...this.notPreferredEmployees],
        (a, b) => a.id === b.id
      );
    }
  },
  watch: {
    serviceToEdit() {
      this.fillExistingData();
    }
  },
  created() {
    this.fillExistingData();
  },
  methods: {
    fillExistingData() {
      this.service = {
        id: this.serviceToEdit ? this.serviceToEdit.id : '',
        no: this.serviceToEdit ? this.serviceToEdit.no : '',
        name: this.serviceToEdit ? this.serviceToEdit.name : '',
        description: this.serviceToEdit ? this.serviceToEdit.description : '',
        price: this.serviceToEdit ? this.serviceToEdit.price : '',
        duration: this.serviceToEdit ? this.serviceToEdit.duration : '',
        fixed_duration: this.serviceToEdit
          ? this.serviceToEdit.fixed_duration
          : false,
        category_id: this.serviceToEdit ? this.serviceToEdit.category_id : '',
        service_parts: this.serviceToEdit
          ? this.serviceToEdit.service_parts
          : []
      };
      this.childServices = this.serviceToEdit
        ? this.serviceToEdit.child_services
        : [];
      this.fillExistingAssociatedData(this.serviceToEdit);
    },
    addEmployee(preference) {
      this.uiStore.openPanel(SERVICES_PANELS_LAYOUT, ServicePanel.ADD_EMPLOYEE);
      this.addingEmployeePreference = preference;
    },
    addWorkplace() {
      this.uiStore.openPanel(
        SERVICES_PANELS_LAYOUT,
        ServicePanel.ADD_WORKPLACE
      );
    },
    addMachine() {
      this.uiStore.openPanel(SERVICES_PANELS_LAYOUT, ServicePanel.ADD_MACHINE);
    },
    newServiceParts() {
      this.service.service_parts.push(
        {
          start: 0,
          duration: 0,
          attended: true,
          service_id: this.service.id
        },
        {
          start: this.service.duration,
          duration: 0,
          attended: true,
          service_id: this.service.id
        }
      );
    },
    updateServiceParts(index: number, value: number) {
      this.service.service_parts = this.serviceParts;
      if (index === this.service.service_parts.length - 1) {
        const part = {
          ...this.service.service_parts[index],
          start: +value,
          duration: +this.service.duration - +value
        };
        this.service.service_parts.splice(index, 1, part);
      }
    },
    addServicePart(index: number) {
      this.servicesStore.unsetValidationErrors();
      this.service.service_parts = this.serviceParts;
      const previousPart = this.service.service_parts[index];
      const part = {
        start: +previousPart.start + +previousPart.duration,
        duration: 0,
        attended: true,
        service_id: this.service.id
      };
      this.service.service_parts.splice(index + 1, 0, part);
    },
    removeServicePart(index) {
      this.servicesStore.unsetValidationErrors();
      this.service.service_parts = this.serviceParts;
      this.service.service_parts.splice(index, 1);
    },
    removeServiceParts() {
      this.service.service_parts = [];
    },
    onSubmit() {
      this.$emit('onFormValidated', {
        service: this.service,
        preferredEmployees: this.preferredEmployees,
        notPreferredEmployees: this.notPreferredEmployees,
        machineModels: this.associatedMachineModels,
        workplaces: this.associatedWorkplaces,
        childServices: this.childServices
      });
    }
  }
});
