















































import Vue, { PropType, VueConstructor } from 'vue';
import { Collection } from '@/store/common/collections';
import { Service } from '@/model/service';
import { Location } from '@/model/location';
import { Region } from '@/model/region';
import { Workplace } from '@/model/workplace';
import ResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import { Employee } from '@/model/employee';
import { Timetable } from '@/model/timetable';
import { TimetableDateException } from '@/model/timetable-date-exception';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ResourceConfigMixin>
>).extend({
  mixins: [ResourceConfigMixin],
  props: {
    collection: {
      type: String as PropType<Collection>,
      required: true
    },
    entity: {
      type: Object as PropType<
        | Service
        | Location
        | Workplace
        | Region
        | Employee
        | Timetable
        | TimetableDateException
      >,
      required: true
    },
    currentEntity: {
      type: Object as PropType<
        | Service
        | Location
        | Workplace
        | Region
        | Employee
        | Timetable
        | TimetableDateException
      >,
      required: true
    },
    appendIcon: {
      type: String as PropType<string | null>,
      default: 'fa-pen'
    }
  },
  computed: {
    bgColor() {
      let hue = '500';
      let bg = '';
      if (this.collection === Collection.WORKPLACES) {
        hue = String((this.entity as Workplace).hue);
      }
      if (
        this.collection === Collection.LOCATIONS ||
        this.collection === Collection.WORKPLACES
      ) {
        bg = 'tw-bg-' + (this.entity as Workplace | Location).color + '-' + hue;
      }
      return bg;
    }
  }
});
