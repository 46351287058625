

















import Vue, { PropType, VueConstructor } from 'vue';
import ColorPicker from '@/components/shared/ColorPicker.vue';
import { Color } from '@/constants';
import FormInputMixin from '@/mixins/FormInputMixin';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof FormInputMixin>
>).extend({
  components: {
    ColorPicker
  },
  mixins: [FormInputMixin],
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    colorSwatch: {
      type: Boolean,
      default: true
    },
    color: {
      type: String as PropType<Color>,
      default: Color.BLUE
    },
    hue: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      selectedColor: '',
      selectedHue: 500,
      displayPicker: false
    };
  },
  watch: {
    color(val: Color) {
      if (val) {
        this.updateColor({ color: val, hue: this.hue });
      }
    },
    hue(val: number) {
      if (val) {
        this.updateColor({ color: this.color, hue: val });
      }
    }
  },
  mounted() {
    this.setColor(this.color || Color.BLUE);
    this.setHue(this.hue || 500);
  },
  methods: {
    setColor(color: Color) {
      this.selectedColor = color;
    },
    setHue(hue: number) {
      this.selectedHue = hue;
    },
    showPicker() {
      document.addEventListener('click', this.handleClickOutsidePicker);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.handleClickOutsidePicker);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateColor({ color, hue }: { color: Color; hue: number }) {
      this.selectedColor = color;
      this.selectedHue = hue;
      this.$emit('onValueChange', { color, hue });
    },
    handleClickOutsidePicker(event) {
      const colorPickerHTMLElement = this.$refs.colorpicker as any;
      if (
        colorPickerHTMLElement &&
        colorPickerHTMLElement !== event.target &&
        !colorPickerHTMLElement.contains(event.target)
      ) {
        this.displayPicker = false;
      }
    }
  }
});
